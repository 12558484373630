<template>
  <div id="app" :class="{ mobile: mobileMenu }">
    <modal />
    <navigation />
    <transition name="fade">
      <!-- <keep-alive include="program"> -->
      <router-view :key="$route.path" />
      <!-- </keep-alive> -->
    </transition>
    <mobile-menu />
    <newsletter v-if="newsletter" />
  </div>
</template>

<script>
const Navigation = () =>
  import(/* webpackChunkName: "Navigation" */ "@/components/Navigation.vue");
const Modal = () =>
  import(/* webpackChunkName: "Modal" */ "@/components/Modal.vue");
const MobileMenu = () =>
  import(/* webpackChunkName: "MobileMenu" */ "@/components/MobileMenu.vue");
const Newsletter = () =>
  import(/* webpackChunkName: "Newsletter" */ "@/components/Newsletter.vue");
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Luma Westbau",
      titleTemplate: "Luma Westbau" + " %s",
    };
  },
  components: {
    Modal,
    Navigation,
    MobileMenu,
    Newsletter,
  },
  data() {
    return {
      mobileMenu: false,
      newsletter: false,
    };
  },
  created() {
    this.$store.dispatch("loadSite");
  },
  watch: {
    $route() {
      this.closeNewsletter();
    },
  },
  mounted() {
    this.$root.$on("toggleMenu", this.toggleMenu);
    window.addEventListener("resize", this.hideMenu);
    this.$root.$on("openNewsletter", this.openNewsletter);
    this.$root.$on("closeNewsletter", this.closeNewsletter);
  },
  methods: {
    openNewsletter() {
      this.newsletter = true;
    },
    closeNewsletter() {
      this.newsletter = false;
    },
    toggleMenu(value) {
      this.mobileMenu = value;
    },
    hideMenu() {
      if (window.innerWidth > 768) {
        this.$root.$emit("toggleMenu", false);
        this.mobileMenu = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
@font-face {
  font-family: "luma";
  src: url("./assets/luma-monospace.ttf");
}
@font-face {
  font-family: "luma-italic";
  src: url("./assets/luma-monospace-italic.ttf");
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
:root {
  --fs: 1rem;
  --lh: calc(1.25 * 1rem);
  --bg: rgb(0, 0, 0);
  --color: rgb(255, 255, 255);
  --hover: rgb(150, 150, 150);
  @media only screen and (max-width: 768px) {
    --fs: 0.9rem;
    --lh: calc(1.25 * 0.9rem);
  }
}
.no-select {
  user-select: none;
}
.play-btn {
  display: inline-block !important;
  vertical-align: top;
  height: calc(0.9 * var(--lh));
  width: auto !important;
  margin-right: calc(0.25 * var(--lh));
}
a,
a:active,
a:hover,
a:visited,
.link {
  color: var(--color);
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &.router-link-exact-active {
    font-family: "luma-italic";
  }
}

p a,
.underline {
  padding-bottom: 1px;
  line-height: 1.1;
  border-bottom: 1px solid var(--color);
}

.dib {
  display: inline-block;
}

.icon {
  height: var(--lh);
  width: auto !important;
  padding-left: calc(0.5 * var(--lh));
}

em,
i {
  font-family: "luma-italic";
}

.table {
  display: flex;
  span {
    flex: 0 0 50%;
  }
  &:last-of-type {
    margin-bottom: var(--lh);
  }
}

h1 {
  font-size: calc(6 * var(--fs));
  line-height: 0.8;
  text-transform: uppercase;
  font-weight: normal;
  @media only screen and (max-width: 768px) {
    font-size: calc(4 * var(--fs));
    line-height: 0.8;
  }
}
p:not(:last-of-type),
.mb {
  margin-bottom: var(--lh);
}
.pl {
  padding-left: var(--lh);
}
.caps {
  text-transform: uppercase;
}
.nm {
  margin: 0 !important;
}

body,
html {
  width: 100%;
  height: 100%;
  font-size: var(--fs);
  line-height: 1.4;
  letter-spacing: -0.025em;
  background: var(--bg);
  color: var(--color);
  overflow-x: hidden;
}

input,
select,
textarea {
  appearance: none;
  outline: none;
  background: var(--bg);
  color: var(--color);
  border: 1px solid var(--color);
  padding: calc(0.5 * var(--lh));
  font-size: var(--fs);
  font-family: "luma", Helvetica, Arial, sans-serif;
  width: 100%;
  max-width: 700px;
  border-radius: 0;
  line-height: 1.4;
  display: block;
  &[type="submit"] {
    background: var(--color);
    color: var(--bg);
    width: auto;
  }
}
select {
  padding-left: calc(1.5 * var(--lh));
  position: relative;
}

#app {
  width: 100%;
  height: 100%;
  font-family: "luma", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(2 * var(--lh));
    padding-top: var(--lh);
    padding-right: var(--lh);
    cursor: pointer;
  }
  &.mobile {
    nav {
      transform: translate(0, calc(-9 * var(--lh)));
    }
  }
  .content-box {
    background: var(--bg);
    margin: 0 auto;
    width:100%;
    padding: var(--lh);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--lh);
    .col {
      grid-column: span 3;
      padding-right: var(--lh);
      img.small {
        max-width:250px;
        width:100%;
      }
    }
    .credits {
      grid-column: span 12;

    }
    .col-2 {
      grid-column: span 9;
      p {
        max-width: 700px;
      }
    }
    .col-3 {
      grid-column: span 3;
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: min-content;
      .col-2 {
        order:1;
      }
      .col {
        order:2;
      }
      .col,
      .col-2,
      .col-3,
      .credits {
        grid-column: span 1;
      }
    }
  }
  .content {
    position: fixed;
    z-index: 10;
    top: calc(3 * var(--lh));
    left: 0;
    right: 0;
    bottom: calc(3 * var(--lh));
    background: var(--bg);
    padding: var(--lh);
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--lh);
    .col {
      grid-column: span 3;
      padding-right: var(--lh);
    }
    .credits {
      grid-column: 1/4;
    }
    .col-2 {
      grid-column: span 6;
      p {
        max-width: 700px;
      }
    }
    .col-3 {
      grid-column: span 3;
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: min-content;
      .col,
      .col-2,
      .col-3,
      .credits {
        grid-column: span 1;
      }
    }
  }
  main {
    width: 100%;
    height: 100%;
    .background,
    .fixed {
      width: 100%;
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  img {
    width: 100%;
    display: block;
  }
}
</style>
