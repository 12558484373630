import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import VueLazyload from "vue-lazyload";
import VueScrollTo from "vue-scrollto";
import VuePlyr from "vue-plyr";
import i18n from "./i18n";
import axios from "axios";
import IdleVue from "idle-vue";
import VueObserveVisibility from "vue-observe-visibility";

Vue.use(VueObserveVisibility);
const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  idleTime: 1000,
};
Vue.use(IdleVue, options);
axios.defaults.auth = {
  username: process.env.VUE_APP_USERNAME,
  password: process.env.VUE_APP_PASSWORD,
};
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
  },
  emit: ["ended"],
});
Vue.use(VueScrollTo, {
  duration: 1000,
  offset: 62,
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueLazyload, {
  preLoad: 1.5,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
