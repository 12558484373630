import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

const auth = {
  username: process.env.VUE_APP_USERNAME,
  password: process.env.VUE_APP_PASSWORD,
};

export default new Vuex.Store({
  state: {
    site: [],
    program: [],
  },
  actions: {
    async loadSite({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "site",
            select: {
              title: true,
              url: true,
              instagram: true,
              facebook: true,
              twitter: true,
            },
          },
          {
            auth,
          }
        )
        .then(function (response) {
          commit("SET_SITE", response.data.result);
        });
    },
    async loadProgram({ commit }) {
      await axios
        .post(
          "/api/query",
          {
            query: "page('program').children.listed",
            select: {
              title: true,
              subtitle: true,
              uid: true,
              current: "page.current.toBool()",
              recording: {
                query: "page.recording.toPage",
                select: {
                  uid: "page.uid",
                  vimeoid: "page.videolink",
                  type: "page.type",
                  title: true,
                  talktitle: "page.talktitle",
                  template: "page.parent.intendedTemplate",
                  parent: "page.parent.uid",
                },
              },
              year: "page.enddate.toDate('Y')",
              startdate: "page.startdate.toDate('d.m.Y')",
              rawstart: "page.startdate.toDate('m/d/Y')",
              enddate: "page.enddate.toDate('d.m.Y')",
              hideenddate: "page.hideenddate.toBool()",
              showmultiple: "page.showmultiple.toBool()",
              multipledates: "page.multipledates.kirbytextinline",
              location: "page.location",
              category: "page.category",
              cover: {
                query: "page.cover.toFile",
                select: {
                  srcset: "file.srcset([200, 400, 600, 800, 1200, 1400, 1600])",
                  thumb: "file.resize(100).url",
                  url: "file.url",
                },
              },
            },
          },
          {
            auth,
          }
        )
        .then(function (response) {
          commit("SET_PROGRAM", response.data.result.data);
        });
    },
  },
  mutations: {
    SET_SITE(state, site) {
      state.site = site;
    },
    SET_PROGRAM(state, pages) {
      state.program = pages;
    },
  },
});
