import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";
import VueI18n from "@/i18n.js";

Vue.use(Router);
export default new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      setTimeout(() => {
        // return { x: 0, y: 0 };
        window.scroll(0, 0);
      }, 300);
    }
  },
  routes: [
    {
      path: "/:lang/",
      component: {
        render: (h) => h("router-view"),
      },
      beforeEnter(to, from, next) {
        const lang = to.params.lang;
        if (!["en", "de"].includes(lang)) return next("de");
        if (VueI18n.locale !== lang) {
          VueI18n.locale = lang;
        }
        return next();
      },
      children: [
        {
          path: "",
          name: "home",
          component: () =>
            import(/* webpackChunkName: "Home" */ "./views/Home.vue"),
        },
        {
          path: "current",
          name: "current",
          component: () =>
            import(/* webpackChunkName: "Current" */ "./views/Current.vue"),
        },
        {
          path: "program",
          name: "program",
          component: () =>
            import(/* webpackChunkName: "Program" */ "./views/Program.vue"),
          beforeEnter: (to, from, next) => {
            if (store.state.program.length === 0) {
              store.dispatch("loadProgram").then(next);
            } else {
              next();
            }
          },
        },
        {
          path: "program/:uid",
          name: "exhibition",
          component: () =>
            import(
              /* webpackChunkName: "Exhibition" */ "./views/Exhibition.vue"
            ),
        },
        {
          path: "recordings",
          name: "recordings",
          component: () =>
            import(
              /* webpackChunkName: "Recordings" */ "./views/Recordings.vue"
            ),
        },
        {
          path: "recordings/:uid",
          name: "recording",
          component: () =>
            import(/* webpackChunkName: "Recording" */ "./views/Recording.vue"),
        },
        {
          path: "recordings/:collection/:uid",
          name: "recording-inner",
          component: () =>
            import(/* webpackChunkName: "Recording" */ "./views/Recording.vue"),
        },
        {
          path: "venue",
          name: "venue",
          component: () =>
            import(/* webpackChunkName: "Venue" */ "./views/Venue.vue"),
        },
        {
          path: "visit",
          name: "visit",
          component: () =>
            import(/* webpackChunkName: "Visit" */ "./views/Visit.vue"),
        },
        {
          path: "privacy-policy",
          name: "privacy-policy",
          component: () =>
            import(
              /* webpackChunkName: "PrivacyPolicy" */ "./views/PrivacyPolicy.vue"
            ),
        },
      ],
    },
    {
      path: "*",
      redirect: "/de/",
    },
  ],
});
